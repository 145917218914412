body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* overrides to toastify */
.Toastify__toast {
    margin: 1rem 3rem;
}

/*overrides modal backdrop*/
.modal-backdrop {
    z-index: 104000 !important;
}

.modal {
    z-index: 105000 !important;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

.zoom-in {
    animation: zoom-in 0.5s;
}

@keyframes slide-in {
    from {
        transform: translateX(50%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes zoom-in {
    0% {
        transform: scale(0, 0);
        opacity: 0;
    }
    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
        border-radius: 10px;
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        border-radius: 10px;
    }
}
