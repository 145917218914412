@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
    height: 100%;
    font-family: "Nunito", sans-serif !important;
    font-size: 16px !important;
}

a:not(.dropdown-item, .btn, .nav-link, .navbar-brand, .footer-link) {
    color: #0495ee !important;
}

.error {
    border: 2px solid #ff6565;
}

.apple-auth-btn {
    width: 100% !important;
}

.sortableHelper {
    z-index: 9999999 !important;
}

.order_start {
    background-color: #60af20;
    color: #fff;
}
